var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container"},[(_vm.couponsList.length > 0)?_c('div',{staticClass:"coupon-list"},_vm._l((_vm.couponsList),function(item,index){return _c('div',{key:index,staticClass:"item acea-row row-center-wrapper"},[_c('div',{staticClass:"money",class:item.is_use ? 'moneyGray' : ''},[_c('div',[_vm._v(" ￥"),_c('span',{staticClass:"num"},[_vm._v(_vm._s(item.coupon_price))])]),_c('div',{staticClass:"pic-num"},[_vm._v("满"+_vm._s(item.use_min_price)+"元可用")])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"condition line1"},[(item.type === 0)?_c('span',{staticClass:"line-title",class:item.is_use === true || item.is_use === 2
                ? 'bg-color-huic'
                : 'bg-color-check'},[_vm._v("通用劵")]):(item.type === 1)?_c('span',{staticClass:"line-title",class:item.is_use === true || item.is_use === 2
                ? 'bg-color-huic'
                : 'bg-color-check'},[_vm._v("品类券")]):_c('span',{staticClass:"line-title",class:item.is_use === true || item.is_use === 2
                ? 'bg-color-huic'
                : 'bg-color-check'},[_vm._v("商品券")]),_c('span',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"data acea-row row-between-wrapper"},[(item.end_time !== 0)?_c('div',[_vm._v(" "+_vm._s(item.start_time ? item.start_time + "-" : "")+_vm._s(item.end_time)+" ")]):_c('div',[_vm._v("不限时")]),(item.is_use === true)?_c('div',{staticClass:"bnt gray"},[_vm._v("已领取")]):(item.is_use === 2)?_c('div',{staticClass:"bnt gray"},[_vm._v("已领完")]):_c('div',{staticClass:"bnt bg-color-red",on:{"click":function($event){return _vm.getCoupon(item.id, index)}}},[_vm._v(" 立即领取 ")])])])])}),0):_vm._e(),_c('Loading',{attrs:{"loaded":_vm.loadend,"loading":_vm.loading}}),(_vm.couponsList.length === 0 && _vm.page > 1)?_c('div',{staticClass:"noCommodity"},[_vm._m(0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"noPictrue"},[_c('img',{staticClass:"image",attrs:{"src":require("@assets/images/noCoupon.png")}})])
}]

export { render, staticRenderFns }